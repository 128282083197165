var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "편안하지흉부외과",
            "tabActive": "고객리뷰",
            "bg": "/images/sub/introduction/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("편안하지흉부외과")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--sm page-section--first page-section--last"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-sheet', {
    staticClass: "board-view"
  }, [_c('div', {
    staticClass: "pa-3 px-md-5 py-md-4 border-b"
  }, [_c('u-txt-default', [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3",
      "md": "1"
    }
  }, [_vm._v("작성자")]), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.review.writer.name,
      callback: function ($$v) {
        _vm.$set(_vm.review.writer, "name", $$v);
      },
      expression: "review.writer.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3",
      "md": "1"
    }
  }, [_vm._v("제목")]), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "7"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.review.subject,
      callback: function ($$v) {
        _vm.$set(_vm.review, "subject", $$v);
      },
      expression: "review.subject"
    }
  })], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "pa-3 px-md-5 py-md-4 border-b"
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "no-resize": "",
      "auto-grow": ""
    },
    model: {
      value: _vm.review.content,
      callback: function ($$v) {
        _vm.$set(_vm.review, "content", $$v);
      },
      expression: "review.content"
    }
  })], 1), _c('div', {
    staticClass: "pa-3 px-md-5 py-md-4 border-b"
  }, [_c('div', {
    staticClass: "g-recaptcha",
    attrs: {
      "id": "g-recaptcha",
      "data-sitekey": "6LfpFgUrAAAAAJzBHHPkyX-03pyvQfi3uUjNe8Cq",
      "data-action": "REVIEW"
    }
  })])])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "ma-n1"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-pill px-3",
    attrs: {
      "color": "grey lighten-3",
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text font-secondary"
  }, [_vm._v("리스트")])])], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-pill px-3",
    attrs: {
      "color": "grey",
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_c('span', {
    staticClass: "font-secondary"
  }, [_vm._v("등록하기")])])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }