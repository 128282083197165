var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-secondary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "편안하지흉부외과",
            "tabActive": "고객리뷰",
            "bg": "/images/sub/introduction/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("편안하지흉부외과")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_vm.review ? _c('page-section-primary', {
    staticClass: "page-section--sm page-section--first page-section--last"
  }, [_c('v-sheet', {
    staticClass: "board-view"
  }, [_c('div', {
    staticClass: "pa-3 px-md-5 py-md-4 border-b"
  }, [_c('u-txt-default', {
    staticClass: "txt--dark font-size-md-18"
  }, [_vm._v(_vm._s(_vm.review.subject))])], 1), _c('div', {
    staticClass: "pa-3 px-md-5 py-md-4 border-b"
  }, [_c('u-txt-default', {
    staticClass: "font-size-md-18"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("작성자 : " + _vm._s(_vm.review.writer.name))]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("작성일 : " + _vm._s(_vm.$dayjs(_vm.review.createdAt).format("YYYY-MM-DD HH:mm:ss")))]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("조회수 : " + _vm._s(_vm.review.viewCount.format()))])], 1)], 1)], 1), _c('div', {
    staticClass: "pa-3 px-md-5 py-md-4 border-b"
  }, [_c('div', {
    staticClass: "board-view__content",
    domProps: {
      "innerHTML": _vm._s(_vm.review.content.replace(/\n/g, '<br>'))
    }
  })])]), _c('v-row', {
    staticClass: "pt-6"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-pill px-3",
    attrs: {
      "color": "grey lighten-3",
      "outlined": "",
      "small": ""
    }
  }, [_c('span', {
    staticClass: "grey--text font-secondary",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("리스트")])])], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }