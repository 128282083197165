<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="편안하지흉부외과" tabActive="고객리뷰" bg="/images/sub/introduction/visual.jpg">
                <template #title>편안하지흉부외과</template>
            </sub-head-primary>
        </template>

        <page-section-primary v-if="review" class="page-section--sm page-section--first page-section--last">
            <v-sheet class="board-view">
                <div class="pa-3 px-md-5 py-md-4 border-b">
                    <u-txt-default class="txt--dark font-size-md-18">{{ review.subject }}</u-txt-default>
                </div>
                <div class="pa-3 px-md-5 py-md-4 border-b">
                    <u-txt-default class="font-size-md-18">
                        <v-row class="row--xxs">
                            <v-col cols="auto">작성자 : {{ review.writer.name }}</v-col>
                            <v-col cols="auto">작성일 : {{ $dayjs(review.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</v-col>
                            <v-col cols="auto">조회수 : {{ review.viewCount.format() }}</v-col>
                        </v-row>
                    </u-txt-default>
                </div>
                <div class="pa-3 px-md-5 py-md-4 border-b">
                    <div v-html="review.content.replace(/\n/g,'<br>')" class="board-view__content" />
                </div>
            </v-sheet>
            <v-row class="pt-6">
                <v-spacer />
                <v-col cols="auto">
                    <v-btn color="grey lighten-3" outlined small class="rounded-pill px-3">
                        <span class="grey--text font-secondary" @click="$router.go(-1)">리스트</span>
                    </v-btn>
                </v-col>
            </v-row>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import api from "@/api";
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import MainReviewCard from "@/components/client/main/review/main-review-card.vue";

import SelectPrimary from "@/components/publish/parents/forms/select-primary.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        MainReviewCard,
        SelectPrimary,
    },
    data() {
        return {
            review: null
        };
    },
    methods: {
        async init(){
            var { review } = await api.v1.easyleg.reviews2.get({ _id: this.$route.params._id });
            this.review = review;
        }
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.board-view {
    border-top: 2px solid var(--v-grey-darken4);
    &__content {
        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
}
</style>