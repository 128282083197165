<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="편안하지흉부외과" tabActive="고객리뷰" bg="/images/sub/introduction/visual.jpg">
                <template #title>편안하지흉부외과</template>
            </sub-head-primary>
        </template>

        <v-container>
            <u-tit-wrap-default class="tit-wrap--lg text-center">
                <u-tit-wrap-default class="tit-wrap tit-wrap--sm">
                    <u-tit-default class="font-secondary">
                        <div class="tit--xs primary--text text--darken-4 mb-8px mb-md-16px">Review</div>
                        <div class="tit--lg font-weight-regular">고객리뷰</div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="txt--lg">편안하지흉부외과는 네이버/카카오 리뷰를 <br class="d-md-none" />‘업체를 통해 조작’하거나 ‘환자분께 요청’하여 <br class="d-md-none" />작성하지 않습니다.</u-txt-default>
            </u-tit-wrap-default>
        </v-container>

        <page-section-primary class="page-section--sm page-section--first page-section--last">
            <v-row justify="end" class="ma-n1">
                <v-col cols="12" md="auto" class="pa-1">
                    <v-select v-model="filter.searchKey" :items="searchKeys" item-text="title" item-value="value" placeholder="선택" dense hide-details outlined class="w-md-110px"></v-select>
                </v-col>
                <v-col cols="12" md="auto" class="pa-1">
                    <v-text-field v-model="filter.searchValue" :disabled="!filter.searchKey" placeholder="검색어를 입력하세요." hide-details outlined height="40" class="w-md-240px" @keydown.enter="search(true)"></v-text-field>
                </v-col>
                <v-col cols="auto" class="pa-1">
                    <v-btn height="40" color="grey" @click="search(true)">
                        <span class="txt white--text">검색</span>
                    </v-btn>
                </v-col>
            </v-row>

            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th v-for="(header, index) in headers" :key="index" :class="`w-${header.width}`">
                                {{ header.title }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(review, index) in reviews" :key="index" @click="navigate(review)">
                            <td>
                                <span class="text-truncate-2">{{ review.subject }}</span>
                            </td>
                            <td class="text-center">{{ review.writer.name }}</td>
                            <td class="text-center">{{ $dayjs(review.createdAt).format("YYYY-MM-DD") }}</td>
                            <td class="text-center"><span class="d-md-none">조회 : </span>{{ review.viewCount.format() }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-row class="pt-5">
                <v-spacer />
                <v-col cols="auto">
                    <v-btn color="primary" :to="`/review/write`" outlined class="rounded-pill font-secondary px-2 px-md-3">리뷰 작성하기</v-btn>
                </v-col>
            </v-row>

            <div class="pagination-wrap">
                <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search(true)" />
            </div>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import api from "@/api";
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import MainReviewCard from "@/components/client/main/review/main-review-card.vue";

import SelectPrimary from "@/components/publish/parents/forms/select-primary.vue";
import TextFieldPrimary from "@/components/publish/parents/forms/text-field-primary.vue";
import btnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

import PaginationComponent from "@/components/client/control/pagination-component.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,

        MainReviewCard,

        SelectPrimary,
        TextFieldPrimary,
        btnPrimary,

        PaginationComponent,
    },
    data() {
        return {
            headers: [
                {
                    title: "제목",
                    width: "60",
                },
                {
                    title: "작성자",
                    width: "15",
                },
                {
                    title: "작성일",
                    width: "15",
                },
                {
                    title: "조회",
                    width: "10",
                },
            ],

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            reviews: [],

            searchKeys: [
                { title: "작성자", value: "writer.name" },
                { title: "제목", value: "subject" },
                { title: "내용", value: "content" },
            ],
        };
    },
    methods: {
        init() {
            this.search();
        },
        async search(routable = false) {
            if (routable) {
                this.$router.push({
                    query: { ...this.filter, page: this.page },
                });
            }

            var { summary, reviews } = await api.v1.easyleg.reviews2.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.reviews = reviews;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        navigate(review) {
            this.$router.push(`/review/${review._id}`);
        },
    },
    mounted() {
        this.init();
    },
    computed: {},
    watch: {},
};
</script>

<style lang="scss" scoped>
:deep(.v-list-item__title) {
    line-height: 14px !important;
}
.w-15 {
    width: 15%;
}
.v-data-table {
    margin-top: 20px;
    border-top: 2px solid var(--v-grey-darken4);
    :deep(table) {
        thead {
            display: none;
            th {
                text-align: center !important;
            }
        }
        tbody {
            tr {
                display: flex;
                flex-wrap: wrap;
                border-bottom: 1px solid var(--border-color);
                background-color: transparent !important;
                cursor: pointer;
                td {
                    height: auto !important;
                    padding: 12px !important;
                    border-bottom: none !important;
                    position: relative;
                    &:first-child {
                        width: 100%;
                    }
                    &:nth-child(2),
                    &:nth-child(3) {
                        &::after {
                            content: "";
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 1px;
                            height: 12px;
                            background-color: var(--border-color);
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .v-data-table {
        :deep(table) {
            thead {
                display: table-header-group;
            }
            tbody {
                tr {
                    display: table-row;
                }
                td {
                    padding: 16px 20px !important;
                    &:first-child {
                        width: auto !important;
                    }
                    &:nth-child(2),
                    &:nth-child(3) {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
</style>