<template>
    <app-secondary>
        <template #contentsHead>
            <sub-head-primary itemTitle="편안하지흉부외과" tabActive="고객리뷰" bg="/images/sub/introduction/visual.jpg">
                <template #title>편안하지흉부외과</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--sm page-section--first page-section--last">
            <v-row class="row--xs">
                <v-col cols="12">
                    <v-sheet class="board-view">
                        <div class="pa-3 px-md-5 py-md-4 border-b">
                            <u-txt-default>
                                <v-row align="center" class="row--xs">
                                    <v-col cols="3" md="1">작성자</v-col>
                                    <v-col cols="9" md="3">
                                        <v-text-field v-model="review.writer.name" outlined hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="3" md="1">제목</v-col>
                                    <v-col cols="9" md="7">
                                        <v-text-field v-model="review.subject" outlined hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                            </u-txt-default>
                        </div>
                        <div class="pa-3 px-md-5 py-md-4 border-b">
                            <!-- <naver-smarteditor id="content" /> -->
                            <v-textarea v-model="review.content" outlined hide-details no-resize auto-grow />
                        </div>
                        <div class="pa-3 px-md-5 py-md-4 border-b">
                            <div id="g-recaptcha" class="g-recaptcha" data-sitekey="6LfpFgUrAAAAAJzBHHPkyX-03pyvQfi3uUjNe8Cq" data-action="REVIEW"></div>
                        </div>
                    </v-sheet>
                </v-col>
                <v-col cols="12">
                    <!-- reCAPTCHA -->
                </v-col>
                <v-col cols="12">
                    <v-row class="ma-n1">
                        <v-spacer />
                        <v-col cols="auto" class="pa-1">
                            <v-btn color="grey lighten-3" outlined small class="rounded-pill px-3"  @click="$router.go(-1)">
                                <span class="grey--text font-secondary">리스트</span>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" class="pa-1">
                            <v-btn color="grey" outlined small class="rounded-pill px-3" @click="save()">
                                <span class="font-secondary">등록하기</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </page-section-primary>
    </app-secondary>
</template>

<script>
import api from "@/api";
import AppSecondary from "@/sets/parents/apps/app-secondary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import MainReviewCard from "@/components/client/main/review/main-review-card.vue";

import SelectPrimary from "@/components/publish/parents/forms/select-primary.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    props: {},
    components: {
        AppSecondary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        MainReviewCard,
        SelectPrimary,
        NaverSmarteditor,
    },
    data() {
        return {
            review: {
                writer: {
                    name: null,
                },
                subject: null,
                content: null,
            }
        };
    },
    methods: {
        init(){
            grecaptcha.enterprise.render('g-recaptcha', {
                'sitekey' : '6LfpFgUrAAAAAJzBHHPkyX-03pyvQfi3uUjNe8Cq',
                'action': 'REVIEW',
            });
        },
        validate(){
            try{
                if(!this.review.writer.name) throw new Error("작성자를 입력해주세요");
                if(!this.review.subject) throw new Error("제목을 입력해주세요");
                if(!this.review.content) throw new Error("내용을 입력해주세요");

                this.review.token = grecaptcha.enterprise.getResponse();
                if(!this.review.token) throw new Error("자동등록방지 체크를 해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },
        async save(){
            if(this.validate()){
                await api.v1.easyleg.reviews2.post(this.review);
                alert("저장되었습니다");
                this.$router.go(-1);
            }
        }
    },
    mounted() {
        this.init();
    },
    computed: {},
    watch: {},
};
</script>

<style lang="scss" scoped>
.board-view {
    border-top: 2px solid var(--v-grey-darken4);
}
</style>